import React, { FC, useMemo, useEffect, useContext } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { VariantModal } from 'types/Form'
import Loading from 'components/layout/Loading'
import { DatalistEntry } from 'types/contentful/types'

export interface State {
    sidebarCollapsed: boolean
    header: THeader
    user: any
    modalContent: any
    modalVariant: VariantModal
    modalIsOpened: boolean
    banks: any[]
    moralTaxRegime: any[]
    physicalTaxRegime: any[]
    kycData: any
}

export type TBreadcrumbs = Array<{
    title: string,
    link: string
}>

export type TSearchbar = {
    inputReference: string,
    title: string
}

export type OptionItem = {
    value: string,
    label: string
}

export type THeader = {
    type: "BREADCRUMBS"
    content: TBreadcrumbs
}
    | {
        type: "SEARCHBAR",
        content: TSearchbar
    } | { type: "NULL" }

const initialState: State = {
    sidebarCollapsed: true,
    header: { type: "NULL" },
    user: null,
    modalContent: null,
    modalVariant: "default",
    modalIsOpened: false,
    banks: [],
    moralTaxRegime: [],
    physicalTaxRegime: [],
    kycData: null
}

type Action =
    {
        type: 'OPEN_MODAL'
    } |
    {
        type: 'CLOSE_MODAL'
    } |
    {
        type: 'SET_MODAL_LOADING'
    } |
    {
        type: 'MODAL_LOADING_START'
    } |
    {
        type: 'MODAL_LOADING_DONE'
    }
    | {
        type: 'SET_MODAL_CONTENT',
        value: any
    }
    | {
        type: 'SET_MODAL_VARIANT',
        value: VariantModal
    }
    | {
        type: 'SIDEBAR_COLLAPSE'
    }
    | {
        type: 'SIDEBAR_EXPAND',
    }
    | {
        type: 'HEADER_SET_BREADCRUMBS'
        value: TBreadcrumbs
    }
    | {
        type: 'HEADER_SET_SEARCHBAR'
        value: TSearchbar
    }
    | {
        type: 'HEADER_SET_NULL'
    }
    | {
        type: 'SET_USER',
        value: any
    }
    | {
        type: 'SET_BANKS',
        value: any
    }
    | {
        type: 'SET_MORAL_TAX_REGIME',
        value: any
    }
    | {
        type: 'SET_PHYSICAL_TAX_REGIME',
        value: any
    }
    | {
        type: 'SET_KYC_DATA',
        value: any
    }


export const UIContext = React.createContext<State | any>(initialState)

UIContext.displayName = 'UIContext'

function uiReducer(state: State, action: Action) {
    switch (action.type) {
        case 'OPEN_MODAL': {
            return {
                ...state,
                modalIsOpened: true
            }
        }
        case 'CLOSE_MODAL': {
            return {
                ...state,
                modalIsOpened: false
            }
        }
        case 'SET_MODAL_LOADING': {
            return {
                ...state,
                modalContent: <Loading />
            }
        }
        case 'MODAL_LOADING_START': {
            return {
                ...state,
                modalContent: <Loading />,
                modalIsOpened: true
            }
        }
        case 'MODAL_LOADING_DONE': {
            return {
                ...state,
                modalContent: <Loading />,
                modalIsOpened: false
            }
        }
        case 'SET_MODAL_CONTENT': {
            return {
                ...state,
                modalContent: action.value
            }
        }
        case 'SET_MODAL_VARIANT': {
            return {
                ...state,
                modalVariant: action.value
            }
        }
        case 'SIDEBAR_COLLAPSE': {
            return {
                ...state,
                sidebarCollapsed: true
            }
        }

        case 'SIDEBAR_EXPAND': {
            return {
                ...state,
                sidebarCollapsed: false
            }
        }
        case 'HEADER_SET_BREADCRUMBS': {
            let header: THeader = {
                type: "BREADCRUMBS",
                content: action.value
            }
            return {
                ...state,
                header
            }
        }
        case 'HEADER_SET_SEARCHBAR': {
            let header: THeader = {
                type: "SEARCHBAR",
                content: action.value
            }
            return {
                ...state,
                header
            }
        }
        case 'HEADER_SET_NULL': {
            let header: THeader = { type: "NULL" }
            return {
                ...state,
                header
            }
        }
        case 'SET_USER': {
            return {
                ...state,
                user: action.value
            }
        }
        case 'SET_BANKS': {
            return {
                ...state,
                banks: action.value
            }
        }
        case 'SET_MORAL_TAX_REGIME': {
            return {
                ...state,
                moralTaxRegime: action.value
            }
        }
        case 'SET_PHYSICAL_TAX_REGIME': {
            return {
                ...state,
                physicalTaxRegime: action.value
            }
        }
        case 'SET_KYC_DATA': {
            return {
                ...state,
                kycData: action.value
            }
        }
    }
}

/// just a sort function to avoid duplicated code
function ArraySort(a: OptionItem, b: OptionItem) {
    return a.value.localeCompare(b.value, "es")
}

export const UIProvider: FC = (props) => {
    const {
        user,
        getAccessTokenSilently
    } = useAuth0();

    const [state, dispatch] = React.useReducer(uiReducer, initialState);

    const openSidebar = () => dispatch({ type: "SIDEBAR_EXPAND" });
    const closeSidebar = () => dispatch({ type: "SIDEBAR_COLLAPSE" })

    const setBreadcrumbs = (breadcrumbs: TBreadcrumbs) => dispatch({ type: "HEADER_SET_BREADCRUMBS", value: breadcrumbs })
    const setSearchbar = (searchbar: TSearchbar) => dispatch({ type: "HEADER_SET_SEARCHBAR", value: searchbar })
    const setHeaderToNull = () => { dispatch({ type: "HEADER_SET_NULL" }) }
    const setUser = (user: any) => { dispatch({ type: "SET_USER", value: user }) }
    const setKycData = (kycData: any) => { dispatch({ type: "SET_KYC_DATA", value: kycData }) }
    const setBanks = (banks: any[]) => { dispatch({ type: "SET_BANKS", value: banks }) }

    const setMoralTaxRegime = (moralTaxRegime: any[]) => { dispatch({ type: "SET_MORAL_TAX_REGIME", value: moralTaxRegime }) }
    const setPhysicalTaxRegime = (physicalTaxRegime: any[]) => { dispatch({ type: "SET_PHYSICAL_TAX_REGIME", value: physicalTaxRegime }) }

    const openModal = () => { dispatch({ type: 'OPEN_MODAL' }) };
    const closeModal = () => { dispatch({ type: 'CLOSE_MODAL' }) };
    const setLoading = () => { dispatch({ type: "SET_MODAL_LOADING" }) };
    const startLoading = () => { dispatch({ type: "MODAL_LOADING_START" }) };
    const closeLoading = () => { dispatch({ type: "MODAL_LOADING_DONE" }) };
    const setModalContent = (content: any) => { dispatch({ type: "SET_MODAL_CONTENT", value: content }) }
    const setModalVariant = (variant: VariantModal) => { dispatch({ type: "SET_MODAL_VARIANT", value: variant }) }

    const fetchUserData = async () => {
        let token = getAccessTokenSilently();
        let response = await fetch(`/api/user/get-user?id=${user?.sub}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        if (response.ok) {
            const user = await response.json()
            setUser(user)
            if (user && user.kyc_full_data) {
                setKycData(user.kyc_full_data)
            }
        }
    }

    const fetchBanksData = async () => {
        let response = await fetch("/api/contentful/get-content-type-entries?contentType=datalistEntry&datalist=banks", {})
        if (response.ok) {

            let banks: OptionItem[] = []
            const entries = await response.json()

            entries.items.forEach((entry: DatalistEntry) => {
                banks.push({
                    value: entry.fields.title,
                    label: entry.fields.title
                })
            })
            setBanks(banks.sort(ArraySort))
        }
    }

    const fetchTaxRegimeData = async () => {
        let response = await fetch("/api/contentful/get-content-type-entries?contentType=datalistEntry&datalist=regFiscal", {})
        if (response.ok) {
            let moralTaxRegimes: OptionItem[] = []
            let physicalTaxRegimes: OptionItem[] = []

            const entries = await response.json()

            entries.items.forEach((entry: DatalistEntry) => {

                if (entry.fields.classifier === "PERSONA_MORAL") {
                    moralTaxRegimes.push({
                        value: entry.fields.title,
                        label: entry.fields.title
                    })
                } else {
                    physicalTaxRegimes.push({
                        value: entry.fields.title,
                        label: entry.fields.title
                    })
                }


            })
            setMoralTaxRegime(moralTaxRegimes.sort(ArraySort))
            setPhysicalTaxRegime(physicalTaxRegimes.sort(ArraySort))
        }
    }

    useEffect(() => {
        fetchUserData()
        fetchBanksData()
        fetchTaxRegimeData()
        // fetchContentfulData();
    }, [])

    const value = useMemo(
        () => ({
            ...state,
            openModal,
            closeModal,
            setLoading,
            setModalContent,
            setModalVariant,
            openSidebar,
            closeSidebar,
            setBreadcrumbs,
            setSearchbar,
            setHeaderToNull,
            setUser,
            fetchUserData,
            setBanks,
            fetchBanksData,
            startLoading,
            closeLoading,
            fetchTaxRegimeData,
            setMoralTaxRegime,
            setPhysicalTaxRegime,
            setKycData
        }),
        [state]
    )

    return <UIContext.Provider value={value} {...props} />
}

export const useUI = () => {
    const context = React.useContext(UIContext)
    if (context === undefined) {
        throw new Error(`useUI must be used within a UIProvider`)
    }
    return context
}

export const ManagedUIContext: FC = ({ children }) => (
    <UIProvider>
        {children}
    </UIProvider>
)