import Image, { ImageProps } from 'next/image';
import { FC, useEffect, useRef } from 'react'

interface IImage {
    src ?: string,
    width ?: number | string,
    height ?: number | string
}
export const Users = ({ width = "24", height = "24", src = "/icons/Users.png" } : IImage) => <Image alt = "icon" width = {width} height = {height} src = {src}/>
export const Sales = ({ width = "24", height = "24", src = "/icons/Dollar.png" } : IImage) => <Image alt = "icon" width = {width} height = {height} src = {src} layout = "intrinsic"/>
export const Library = ({ width = "24", height = "24", src = "/icons/Library.png" } : IImage) => <Image alt = "icon" width = {width} height = {height} src = {src}/>
export const Payout = ({ width = "24", height = "24", src = "/icons/Credit-card.png" } : IImage) => <Image alt = "icon" width = {width} height = {height} src = {src}/>
export const Chart = ({ width = "24", height = "24", src = "/icons/Chart-bar-3.png" } : IImage) => <Image alt = "icon" width = {width} height = {height} src = {src}/>
export const Blocks = ({ width = "24", height = "24", src = "/icons/Blocks.png" } : IImage) => <Image alt = "icon" width = {width} height = {height} src = {src}/>
export const Chat = ({ width = "24", height = "24", src = "/icons/Chat.png" } : IImage) => <Image alt = "icon" width = {width} height = {height} src = {src}/>
export const Settings = ({ width = "24", height = "24", src = "/icons/Settings.png" } : IImage) => <Image alt = "icon" width = {width} height = {height} src = {src}/>
export const Signout = ({ width = "24", height = "24", src = "/icons/Signout.png" } : IImage) => <Image alt = "icon" width = {width} height = {height} src = {src}/>
export const ArrowFromRight = ({ width = "24", height = "24", src = "/icons/ArrowFromRight.png" } : IImage) => <Image alt = "icon" width = {width} height = {height} src = {src}/>
export const ArrowToRight = ({ width = "24", height = "24", src = "/icons/ArrowToRight.png" } : IImage) => <Image alt = "icon" width = {width} height = {height} src = {src}/>
export const Calendar = ({ width = "32", height = "32", src = "/icons/Calendar.png" } : IImage) => <Image alt = "icon" width = {width} height = {height} src = {src}/>
export const Notification = ({ width = "32", height = "32", src = "/icons/Notification.png" } : IImage) => <Image alt = "icon" width = {width} height = {height} src = {src}/>
export const ChevronRight = ({ width = "24", height = "24", src = "/icons/ChevronRight.png" } : IImage) => <Image alt = "icon" width = {width} height = {height} src = {src}/>

export const ChevronUp = ({ width = "32", height = "32", src = "/icons/ChevronUp.png" } : IImage) => <Image alt = "icon" width = {width} height = {height} src = {src}/>
export const ChevronDown = ({ width = "32", height = "32", src = "/icons/ChevronDown.png" } : IImage) => <Image alt = "icon" width = {width} height = {height} src = {src}/>

export const UserId = ({ width = "32", height = "32", src = "/icons/User.png" } : IImage) => <Image alt = "icon" width = {width} height = {height} src = {src}/>
export const DocumentFile = ({ width = "32", height = "32", src = "/icons/DocumentFile.png" } : IImage) => <Image alt = "icon" width = {width} height = {height} src = {src}/>
export const Home = ({ width = "32", height = "32", src = "/icons/Home.png" } : IImage) => <Image alt = "icon" width = {width} height = {height} src = {src}/>
export const Return = ({ width = "98", height = "24", src = "/icons/Return.png" } : IImage) => <Image alt = "icon" width = {width} height = {height} src = {src}/>
export const Signature = ({ width = "32", height = "32", src = "/icons/Signature.png" } : IImage) => <Image alt = "icon" width = {width} height = {height} src = {src}/>
export const FileChecked = ({ width = "32", height = "32", src = "/icons/FileChecked.png" } : IImage) => <Image alt = "icon" width = {width} height = {height} src = {src}/>

export const AddPerson = ({ width = "24", height = "24", src = "/icons/AddPerson.png" } : IImage) => <Image alt = "icon" width = {width} height = {height} src = {src}/>
export const Trash = ({ width = "24", height = "24", src = "/icons/Trash.png" } : IImage) => <Image alt = "icon" width = {width} height = {height} src = {src}/>
export const Edit = ({ width = "24", height = "24", src = "/icons/Edit.png" } : IImage) => <Image alt = "icon" width = {width} height = {height} src = {src}/>
export const ReadyChecked = ({ width = "24", height = "24", src = "/icons/ReadyChecked.png" } : IImage) => <Image alt = "icon" width = {width} height = {height} src = {src}/>
export const Cancel = ({ width = "22", height = "22", src = "/icons/RedClose.png" } : IImage) => <Image alt = "icon" width = {width} height = {height} src = {src}/>