import React, { FC, useMemo, useEffect, useContext } from 'react'

export interface State {
    currentStep: number
    onboardingError: string
}

export type TBreadcrumbs = Array<{
    title: string,
    link: string
}>

export type TSearchbar = {
    inputReference: string,
    title: string
}

export type THeader = {
    type: "BREADCRUMBS"
    content: TBreadcrumbs
}
    | {
        type: "SEARCHBAR",
        content: TSearchbar
    } | { type: "NULL" }

const initialState: State = {
    currentStep: 0,
    onboardingError: ''
}

type Action =
    {
        type: 'NEXT_STEP'
    }
    | {
        type: 'PREVIOUS_STEP',
    }
    | {
        type: 'SET_STEP',
        value: number
    }
    |
    {
        type: 'SET_ONBOARDING_ERROR',
        value: string
    }



export const ODContext = React.createContext<State | any>(initialState)

ODContext.displayName = 'ODContext'

function odReducer(state: State, action: Action) {
    switch (action.type) {
        case "NEXT_STEP": {
            return {
                ...state,
                currentStep: state.currentStep + 1
            }
        }

        case "PREVIOUS_STEP": {
            return {
                ...state,
                currentStep: state.currentStep - 1
            }
        }

        case "SET_STEP": {
            return {
                ...state,
                currentStep: action.value
            }
        }
        case "SET_ONBOARDING_ERROR": {
            return {
                ...state,
                onboardingError: action.value
            }
        }
    }
}

export const ODProvider: FC = (props) => {

    const [state, dispatch] = React.useReducer(odReducer, initialState);

    const NextStep = () => dispatch({ type: "NEXT_STEP" })
    const PreviousStep = () => dispatch({ type: "PREVIOUS_STEP" });
    const setStep = (val: number) => dispatch({ type: "SET_STEP", value: val })
    const setOnboardingError = (val: string) => dispatch({ type: "SET_ONBOARDING_ERROR", value: val })

    const value = useMemo(
        () => ({
            ...state,
            NextStep,
            PreviousStep,
            setStep,
            setOnboardingError
        }),
        [state]
    )

    return <ODContext.Provider value={value} {...props} />
}

export const useOD = () => {
    const context = React.useContext(ODContext)
    if (context === undefined) {
        throw new Error(`useOD must be used within a ODProvider`)
    }
    return context
}

export const ManagedODContext: FC = ({ children }) => (
    <ODProvider>
        {children}
    </ODProvider>
)