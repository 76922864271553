import { useAuth0 } from '@auth0/auth0-react'
import React, { FC, useMemo, useEffect, useContext } from 'react'

export interface State {
    deposit: any,
    depositInfo: any,
    currentStep: number
    investmentAmount: number
}

const initialState: State = {
    deposit: null,
    depositInfo: null,
    currentStep: 0,
    investmentAmount: 0
}

type Action =
    {
        type: 'NEXT_DEPOSIT_STEP'
    }
    | {
        type: 'PREVIOUS_DEPOSIT_STEP',
    }
    | {
        type: 'SET_STEP',
        value: number
    }
    | {
        type: 'SET_INVESTMENT_AMOUNT',
        value: any
    }
    | {
        type: 'SET_DEPOSIT',
        value: any
    }
    | {
        type: 'SET_DEPOSIT_INFO',
        value: any
    }

export const DPContext = React.createContext<State | any>(initialState)
DPContext.displayName = 'DPContext'

function dpReducer(state: State, action: Action) {
    switch (action.type) {
        case "NEXT_DEPOSIT_STEP": {
            return {
                ...state,
                currentStep: state.currentStep + 1
            }
        }
        case "PREVIOUS_DEPOSIT_STEP": {
            return {
                ...state,
                currentStep: state.currentStep - 1
            }
        }
        case "SET_STEP": {
            return {
                ...state,
                currentStep: action.value
            }
        }
        case "SET_INVESTMENT_AMOUNT": {
            return {
                ...state,
                investmentAmount: action.value
            }
        }
        case "SET_DEPOSIT": {
            return {
                ...state,
                deposit: action.value
            }
        }
        case "SET_DEPOSIT_INFO": {
            return {
                ...state,
                depositInfo: action.value
            }
        }
    }
}

export const DepositProvider: FC = (props) => {

    const {
        getAccessTokenSilently
    } = useAuth0();

    const [state, dispatch] = React.useReducer(dpReducer, initialState);

    const NextDepositStep = () => dispatch({ type: "NEXT_DEPOSIT_STEP" })
    const PreviousDepositStep = () => dispatch({ type: "PREVIOUS_DEPOSIT_STEP" });
    const setStep = (val: number) => dispatch({ type: "SET_STEP", value: val })
    const setInvestmentAmount = (val: any) => dispatch({ type: "SET_INVESTMENT_AMOUNT", value: val })
    const setDeposit = (deposit: any) => { dispatch({ type: "SET_DEPOSIT", value: deposit }) }
    const setDepositInfo = (depositInfo: any) => { dispatch({ type: "SET_DEPOSIT_INFO", value: depositInfo }) }


    const fetchDepositInfoData = async () => {
        let token = getAccessTokenSilently();
        let response = await fetch(`/api/deposit/get-deposit-info`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        if (response.ok) {
            setDepositInfo(await response.json())
        }
    }

    useEffect(() => {
        fetchDepositInfoData()        
    }, [])

    const value = useMemo(
        () => ({
            ...state,
            NextDepositStep,
            PreviousDepositStep,
            setStep,
            setInvestmentAmount,
            setDeposit,
            fetchDepositInfoData,
            setDepositInfo
        }),
        [state]
    )

    return <DPContext.Provider value={value} {...props} />
}

export const useDP = () => {
    const context = React.useContext(DPContext)
    if (context === undefined) {
        throw new Error(`useDP must be used within a DepositProvider`)
    }
    return context
}

export const ManagedDPContext: FC = ({ children }) => (
    <DepositProvider>
        {children}
    </DepositProvider>
)