import Image from 'next/image';
import Link from 'next/dist/client/link';

export const FullLogo = () => {
    return <div className="h-full">
        <Link href="/">
            <Image
                src="/logos/white_logo.png"
                width="262"
                height="131"
                layout="intrinsic"
                alt="LOGO"
            />
        </Link>
    </div>
}