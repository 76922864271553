
import Link from 'next/link'
import { useAuth0 } from "@auth0/auth0-react";
import { useUI, THeader, TBreadcrumbs, TSearchbar } from 'contexts/UI/UIManager'
import { FullLogo } from 'components/utils';

const NON_PBG_LOGO_PATHS = ["/", "/od/ready"]

const Header = ({ currentPath }: any) => {
    const {
        logout,
    } = useAuth0();

    const {
        header
    } = useUI();

    // if ((header as THeader).type == "BREADCRUMBS") return <div classNameName={cn(s.root, s.breadcrumbs)}>
    //     {
    //         (header.content as TBreadcrumbs).map((item, i) => (
    //             <Link href={item.link} key={item.link + item.title + i}>
    //                 <a><span>{item.title}</span> <Icons.ChevronRight /></a>
    //             </Link>
    //         ))
    //     }
    // </div>
    // if ((header as THeader).type == "SEARCHBAR") return <div classNameName={cn(s.root, s.searchbar)}>
    //     <h1>
    //         {(header.content as TSearchbar).title}
    //     </h1>
    //     <Searchbar />
    // </div>
    return <nav className="flex h-28 justify-between bg-[#0D0C10] w-full py-2 font-light ">
        <div className="w-full flex items-center">
            <div className="w-40">
                <FullLogo />
            </div>
        </div>
        <div className="w-full flex items-center lg:w-auto space-x-3">
            <div className='font-bold flex flex-col w-32'>
                <span className='text-[#00D7A3] '>FONDOS</span>
                <span>{'1000 ($1M)'}</span>
            </div>
            <div className='flex flex-row cursor-pointer rounded-lg bg-[#322e3f] w-80 space-x-5 py-2 hover:bg-[#5b5865]'>
                <div className="flex flex-col text-right w-full">
                    <div className="font-bold">
                        <span className='text-[#00D7A3]'>P/L </span><span className='text-alert'>-2,809.23</span>
                    </div>
                    <div>
                        <span className='text-text-primary text-opacity-50'>10/01/2022 - 20/01/2022</span>
                    </div>
                </div>
                <div className='flex items-center pr-4'>
                    <span>icon</span>
                </div>
            </div>
            <div className='flex flex-row cursor-pointer rounded-lg bg-[#322e3f] w-80 space-x-5 py-2 hover:bg-[#5b5865]'>
                <div className="flex flex-col text-right w-full">
                    <div className="font-bold">
                        <span className='text-[#FEFEFE]'>NOMBRE DEL CLIENTE</span>
                    </div>
                    <div>
                        <span className='text-text-primary text-opacity-50'>Miembro desde: 22/12/22</span>
                    </div>
                </div>
                <div className='flex items-center pr-4'>
                    <span>icon</span>
                </div>
            </div>
            <div className="text-sm px-2 leading-none underline lg:text-lg ">
                <Link href="/">
                    <a onClick={(e) => logout({ returnTo: window.location.origin })}>Cerrar sesión</a>
                </Link>
            </div>
        </div>
    </nav>
}

export default Header;