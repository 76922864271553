import s from './Content.module.css'
import { FC, useEffect, useRef } from 'react'
import cn from 'classnames';


const Content: FC = ({ children }) => {

    return <div className={cn(s.root)}>
        <section className = {cn(s.content)}>
            {children}
        </section>
    </div>
}

export default Content;