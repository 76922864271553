import cn from 'classnames'
import React, { FC, useState, useEffect, useRef, useMemo } from 'react'
import s from './Loading.module.css'
import Image from 'next/image'

const Loading = () =>{
    return <div>
         <div className={cn(s.spin)}></div>
        {/* <p className={cn(s.text)}>Cargando...</p> */}
    </div>
}

export default Loading